/* eslint-disable */
/* prettier-ignore */
export const WebsiteData = {
    campaign_title: "Lucky Universal",
    main_page_sub_title:
      "Enjoy a fantastic selection of free social casino games! Connect with friends and experience endless entertainment—all at no cost!",
    blockData: [
      {
        img: "./img/blocks/block1.png",
        text: "Join an engaging social space where players can connect, share experiences, and enjoy games together. Be part of the fun today!",
      },
      {
        img: "./img/blocks/block1.png",
        text: "Experience a vibrant gaming community where players interact, chat, and create unforgettable moments. Join now for a more thrilling adventure!",
      },
    ],
    bg: "./img/bg/bg_223.png",
    main_title: "Step into a pulse-pounding adventure within the vibrant sphere of social gaming",
    phone: "(457) 390-9814",
    logo: "./img/logo/logo.png",
    colors: ["#D8CAE9", "#956797"],
    why_with_us_texts : [
      {
        "why_text": "Step into a dazzling world of free-to-play games, where endless excitement awaits—enjoy every thrill at your own pace!",
        "why_label": "Unrivaled Entertainment"
      },
      {
        "why_text": "Immerse yourself in pulse-pounding gaming adventures filled with surprises and rewarding moments that keep your adrenaline soaring!",
        "why_label": "Electrifying Gameplay"
      },
      {
        "why_text": "Embark on an engaging social gaming journey—compete, connect, and enjoy the camaraderie with fellow players!",
        "why_label": "Interactive & Enjoyable"
      },
      {
        "why_text": "Enter a realm of constant excitement, where every spin and deal releases a burst of fresh thrills at your command!",
        "why_label": "Unending Game Action"
      }
    ] 
    
    
    
  };
