import * as i from "./imports";
import PhoneTwoToneIcon from "@mui/icons-material/PhoneTwoTone";
import InfoIcon from "@mui/icons-material/Info";

import VideogameAssetIcon from "@mui/icons-material/VideogameAsset";
import GroupWorkTwoToneIcon from "@mui/icons-material/GroupWorkTwoTone";
import RestartAltTwoToneIcon from "@mui/icons-material/RestartAltTwoTone";

export const Header = () => {
  const [anchorEl, setAnchorEl] = i.React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const navigate = i.useNavigate();
  const [auth, setAuth] = i.useState(false);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const token = localStorage.getItem("token");
  const logoutHandler = () => {
    setAuth(false);
    localStorage.removeItem("token");
    navigate(i.ROUTER.SIGN_IN);
  };
  i.useEffect(() => {
    if (!token) return;
    setAuth(true);
  });
  return (
    <div
      className="sticky top-0  w-full h-[100px]  bg-black bg-opacity-90 flex items-center justify-center w-full "
      style={{ zIndex: 80 }}
    >
      <div className="w-full lg:w-[75%] xl:w-[50%] h-[50px] flex justify-between items-center px-[16px]">
        <div>
          <img
            src={i.WebsiteData.logo}
            width={86}
            height={20}
            alt="logo"
            className="cursor-pointer pt-[10px] hover:scale-[103%]"
            onClick={() => {
              navigate(i.ROUTER.HOME);
            }}
          />
        </div>
        <div className="md:hidden">
          <i.Button
            id="fade-button"
            aria-controls={open ? "fade-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
          >
            <i.MenuIcon />
          </i.Button>
          <i.Menu
            id="fade-menu"
            MenuListProps={{
              "aria-labelledby": "fade-button",
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            TransitionComponent={i.Fade}
          >
            {i.routerLink(i.ROUTER.HOTTEST_GAMES, "Games", handleClose)}

            {i.routerLink(i.ROUTER.ABOUT_US, "About Us", handleClose)}
            {i.routerLink(i.ROUTER.CONTACT, "Contact", handleClose)}
            {i.routerLink(i.ROUTER.SIGN_IN, "Account", handleClose)}
          </i.Menu>
        </div>
        <div
          className={
            "hidden md:flex gap-[15px] justify-center w-full max-w-[1000px]"
          }
        >
          {i.routerLinkXl(
            i.ROUTER.HOTTEST_GAMES,
            "Games",
            i.WebsiteData.colors[0],
            true,
            VideogameAssetIcon,
            "Monsieur"
          )}

          {i.routerLinkXl(
            i.ROUTER.ABOUT_US,
            "ABOUT US",
            i.WebsiteData.colors[0],
            true,
            InfoIcon,
            "Monsieur"
          )}
          {i.routerLinkXl(
            i.ROUTER.CONTACT,
            "Contact",
            i.WebsiteData.colors[0],
            true,
            PhoneTwoToneIcon,
            "Monsieur"
          )}
          {auth ? (
            <i.Button
              variant="contained"
              color="warning"
              onClick={logoutHandler}
            >
              Logout
            </i.Button>
          ) : (
            i.routerLinkXl(
              i.ROUTER.SIGN_IN,
              "Account",
              i.WebsiteData.colors[0],
              true,
              RestartAltTwoToneIcon,
              "Monsieur"
            )
          )}
        </div>
      </div>
    </div>
  );
};
