export enum ROUTER {
  HOME = "/",
  CONTACT = "/support-channel-page",
  NEWEST_GAMES = "/new-game-spotlights-section",
  HOTTEST_GAMES = "/games",
  ALL_GAMES = "/total-game-selections",
  CONTACT_US = "/support-us",
  TERMS = "/terms-of-access-and-terms-of-use",
  COOKIES = "/privacy-management",
  ABOUT_US = "/our-journey",
  ALL_IN_ONE = "/one-source",
  SIGN_IN = "/sign-in-zone",
  REGISTRATION = "/register-link",
  PAGE = "/page",
}
