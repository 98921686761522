import * as i from "./imports";
import { ButtonCustom } from "../btn-custom";
export const routerLink = (
  link: string,
  title: string,

  handleClose: () => void
) => {
  return (
    <i.Link to={link}>
      <i.MenuItem onClick={handleClose}>{title}</i.MenuItem>
    </i.Link>
  );
};

export const routerLinkXl = (
  link: string,
  title: string,
  color: string,
  header?: boolean,
  IconComponent?: React.ElementType | null,
  Font?: string
) => {
  return (
    <i.Link to={link}>
      <ButtonCustom>
        {IconComponent ? <IconComponent /> : null} {title}
      </ButtonCustom>
    </i.Link>
  );
};
