import { Button as BaseButton, buttonClasses } from "@mui/base/Button";
import { styled } from "@mui/system";
import { color } from "framer-motion";

export const ButtonCustom = styled(BaseButton)(({ theme }) => ({
  fontFamily: "Monsieur",
  fontWeight: 600,
  fontSize: "0.875rem",
  lineHeight: 1.5,
  textDecoration: "none",
  textTransform: "uppercase",
  padding: "8px 16px",

  color: "white",
  transition: "all 150ms ease",
  cursor: "pointer",
  borderBottom: "1px solid rgb(96, 95, 95)",
  "&:hover": {
    borderBottom: "1.5px solid",
    color: "rgb(99, 127, 167)",
  },
}));
