import React, { useRef } from "react";
import {
  WebsiteData,
  ROUTER,
  getOppositeColor,
  imgs,
  useInView,
  routerLinkXl,
  LocalPhoneIcon,
  AlternateEmailIcon,
} from "./imports"; // Сделайте импорты более точными
import { motion } from "framer-motion"; // Используйте правильный импорт для framer-motion и других библиотек
import { Link } from "react-router-dom";

export const Footer = () => {
  const date = new Date();
  const year = date.getFullYear();
  const ref = useRef(null);
  const isInView = useInView(ref, { once: false });

  const oppositeColor = getOppositeColor(WebsiteData.colors[1]);
  const campaignTitle = WebsiteData.campaign_title.split(" ").join("");

  return (
    <div
      className="w-full h-fit min-h-[343px] mt-auto flex flex-col flex-col-reverse justify-start items-center mt-[10px]"
      style={{ backgroundColor: "rgb(12, 11, 15)" }}
    >
      <div className="flex lg:w-[75%] xl:w-[50%] flex-col justify-start items-center gap-[15px]">
        <div className="w-full h-fit flex mx-[32px] flex-col items-center lg:flex-row justify-between gap-[16px] m-[2rem] flex-col-reverse lg:flex-row-reverse">
          <div className="w-full flex flex-col lg:flex-row items-center justify-center gap-[16px]">
            {routerLinkXl(
              ROUTER.COOKIES,
              "Cookies & Privacy",
              WebsiteData.colors[1],
              false
            )}
            <Link to={ROUTER.HOME}>
              <motion.div
                animate={{
                  scale: isInView ? [0, 1] : 0,
                }}
                transition={{ duration: 2, ease: "easeInOut" }}
                ref={ref}
              >
                <img
                  className="cursor-pointer drop-shadow-[0_0_10px_rgba(255,69,0,0.6)] md:drop-shadow-[0_0_10px_rgba(255,140,0,0.8)] lg:drop-shadow-[0_0_20px_rgba(255,69,0,0.9)] transition-all duration-500"
                  src={WebsiteData.logo}
                  height={63}
                  width={200}
                  alt="logo"
                />
              </motion.div>
            </Link>
            {routerLinkXl(
              ROUTER.TERMS,
              "Terms & Conditions",
              WebsiteData.colors[1],
              false
            )}
          </div>
        </div>

        <div className="w-full h-fit flex mx-[5%] flex-col gap-[10px] sm:flex-row items-center justify-center gap-[16px] m-[2rem] flex-wrap flex-col-reverse sm:flex-row-reverse">
          <div style={{ fontFamily: "Monsieur", color: oppositeColor }}>
            <LocalPhoneIcon style={{ fill: oppositeColor }} />{" "}
            {WebsiteData.phone}
          </div>
          <div className="text-break text-center">
            <AlternateEmailIcon style={{ fill: oppositeColor }} />{" "}
            <span
              style={{
                fontFamily: "Monsieur",
                color: oppositeColor,
              }}
              className="text-center"
            >
              support @{campaignTitle}.com
            </span>
          </div>
        </div>

        <p
          className="text-center mx-[5%] font-[16px] text-break"
          style={{
            fontFamily: "Monsieur",
            color: oppositeColor,
          }}
        >
          Get ready to dive into a world of thrilling games designed purely for
          your entertainment. At Lucky Universal, we provide a free online
          social casino platform where all game activities and rewards are
          virtual and hold no monetary value. This means you can enjoy the
          excitement of casino games without any risk to your real money. Please
          be aware that Lucky Universal way is meant for individuals aged 19 and
          older. By using our platform, you affirm that you meet this age
          requirement.Real money is neither involved nor rewarded in our gaming
          activities. This is a virtual game. No real money or prizes can be
          won. By using our platform, you consent to the use of cookies in line
          with GDPR regulations. Please review our cookie policies for more
          information.
        </p>

        <div className="flex justify-center items-center mx-[80px]">
          <img src={imgs.footerImg} alt="footer img" height={63} width={200} />
        </div>

        <div
          className="flex justify-center items-center mx-[5%] text-center"
          style={{
            fontFamily: "Monsieur",
            color: oppositeColor,
          }}
        >
          {WebsiteData.campaign_title} © {year} All Rights Reserved
        </div>
      </div>
    </div>
  );
};
