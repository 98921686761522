import * as i from "./imports";
export const BaseLayout = ({
  children,
  cl,
}: {
  children: i.ReactNode;
  cl?: string;
}) => {
  const backgroundStyle = `linear-gradient(to bottom, #1a1a1a, #0d0d0d, #000000)`;

  return (
    <div
      className={`flex flex-col min-h-screen w-full relative ${cl} items-center justify-center`}
      style={{
        background: backgroundStyle,
      }}
    >
      {<i.Header />}
      <main className="z-1 w-full lg:w-[75%] xl:w-[50%] h-fit flex items-start justify-center">
        {children}
      </main>
      {<i.Footer />}
    </div>
  );
};
