import * as i from "./imports";

function App() {
  return (
    <i.Router>
      <div className="w-full h-fit b z-999">
        <i.Routes>
          {i.routeComponent(i.ROUTER.HOME, i.AllGames)}
          {i.routeComponent(i.ROUTER.CONTACT, i.Contact)}
          {i.routeComponent(i.ROUTER.ABOUT_US, i.About)}
          {i.routeComponent(i.ROUTER.NEWEST_GAMES, i.AllGames)}
          {i.routeComponent(i.ROUTER.ALL_GAMES, i.AllGames)}
          {i.routeComponent(i.ROUTER.HOTTEST_GAMES, i.HotGames)}
          {i.routeComponent(i.ROUTER.COOKIES, i.Cookies)}
          {i.routeComponent(i.ROUTER.TERMS, i.Terms)}
          {i.routeComponent(i.ROUTER.SIGN_IN, i.SignIn)}
          {i.routeComponent(i.ROUTER.REGISTRATION, i.Registration)}
        </i.Routes>
      </div>
    </i.Router>
  );
}

export default App;
